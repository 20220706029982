import { Injectable } from '@angular/core';
import { CallableNames, DbService } from '../../db.service';
import { IUserPreference, IUserPreferencesData } from 'src/app/shared/models/user-preference/user-preference.model';
import { Observable } from 'rxjs';

interface ICallablesUserPreferencesGetForUserData {
  userUuid: string;
  orgId: number;
}

interface ICallablesUserPreferencesUpsertData {
  userUuid: string;
  orgId: number;

  preferences: Partial<IUserPreferencesData>;
}

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {

  constructor(
    private dbService: DbService
  ) { }

  public getForUser(data: ICallablesUserPreferencesGetForUserData): Observable<IUserPreference | null> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesUserPreferencesGetForUser, data });
    return this.dbService.handleObs(obs, { succSilent: true, errSilent: true });
  }

  public upsert(data: ICallablesUserPreferencesUpsertData): Observable<IUserPreference> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesUserPreferencesUpsert, data });
    return this.dbService.handleObs(obs, { succSilent: true, errSilent: true });
  }
}
